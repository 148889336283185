.faq {
  margin: 0;
  padding-top: 1.6rem;
  &__question {
    padding-left: 2rem;
    position: relative;
    cursor: pointer;
    padding-bottom: 0.8rem;

    &:before {
      font-size: 2.4rem;
      content: '+';
      position: absolute;
      top: -1rem;
      left: 0;
    }

    &.open {
      &:before {
        content: '\2212';
      }
    }

    h3 {
      color: $active;
      font-size: 1.8rem;
      opacity: 1;
      transition: opacity 0.3s;
      line-height: 1.3em;

      &:hover {
        opacity: 0.6;
      }

    }
  }
  &__answer {
    margin: 0;
    display: none;
    max-width: 60rem;

    & > p {
      margin-top: 0;
    }

    ul, ol {
      margin: 1em 0;
    }

    ul {
      margin-left: 2.4rem;
      list-style-type: disc;
    }

    &.open {
      display: block;
    }
  }
}
