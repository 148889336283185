.global-announcement {
	display: block;
	background: $active;
	color: white;
	text-align: center;
	padding: 2.4rem 1.6rem;
	opacity: 1;
	transition: opacity 0.3s;
	line-height: 1.2em;

	&__faux-link {
		font-size: 1.3rem;
		color: white;
	}

	&:hover {
		opacity: 0.8;
	}
}
