@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements
@import url('//fonts.googleapis.com/css?family=Crimson+Text:400,400i|Noto+Sans:700|Montserrat:500,600');

// Our variables
$base-font-family: 'Crimson Text', serif;
$base-font-size:	2rem;
$base-font-weight:	400;

$heading-font: 'Montserrat', sans-serif;

$base-line-height:	1.5;

$base-text-color:	#555;
$background-color: 	#F0F0F0;

$wrapper-width: 160rem;

$active: #4AA7A8;

%small-caps {
	font-family: $heading-font;
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 600;
	letter-spacing: 0.5px;
}

html,
body {
	padding: 0;
	margin: 0;
	position: relative;
  height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	font-size: $base-font-size;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
}

.content-main a:not(.button):not(.recipe) {
	color: $active;
	opacity: 1;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.6;
	}
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
	font-family: $heading-font;
  font-weight: 500;
	line-height: 1em;
	padding-bottom: 0.35em;
	color: #444;

	& + p {
		margin-top: 0;
	}

}

h1, .h1 {
  font-size: 4rem;
}

h2, .h2 {
  font-size: 3rem;
}

h3, .h3 {
  font-size: 2rem;
}

.heading--small {
	@extend %small-caps;
}

hr {
	border: 0;
	height: 1px;
	background: #ccc;
	margin: 1.5em 0;
}

.wrapper-global {
	min-height: 100%;
	padding-bottom: 83px;
	position: relative;
	width: 100%;
	max-width: $wrapper-width;
	margin: 0 auto;
}

.wrapper {
	display: block;
	width: 100%;
	max-width: 140rem;
	margin: 0 auto;
	padding: 0 1.6rem;
	position: relative;
}

#recipes, #home {

	&.content-main {
		padding-top: 4.8rem;
	}

}

.content-main {
	padding-top: 3.2rem;

	section {
		padding: 4.8rem 0;

		&.section--pad-bottom {
			padding-top: 0;
		}

		&.section--slim-top {
			padding-top: 0;
		}

	}

}

.promos {
	display: flex;
	flex-wrap: nowrap;

	.promo {
		padding: 0.8rem;
	}

	.half {
		width: 50%;
	}

	.third {
		width: 33.33333%;
	}

}

.cta {
	text-align: left;
	padding-top: 0.8rem;

	.button {
		margin-left: 0;
		margin-right: 2.4rem;
	}

	&--right {
		.button {
			margin-left: 2.4rem;
			margin-right: 0;
		}
	}
}

.button {
	@extend %small-caps;
	display: inline-block;
	padding: 1rem 1.6rem;
	color: white;
	border: 0;
	background: $active;
	border-radius: 5px;
	text-align: center;
	opacity: 1;
	transition: opacity 0.3s;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	&[disabled] {
		color: rgba(255,255,255, 0.5);
		cursor: default;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -1.2rem;
			margin-left: -1rem;
			display: block;
			content:'';
			width: 2rem;
			height: 2rem;
			border: 2px solid transparent;
			border-top-color: white;
			border-radius: 50%;
			animation: spin .8s ease infinite;
		}
	}
}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@import 'global-announcement';
@import 'global-header-nav';
@import 'global-footer';
@import 'component-toaster';
@import 'component-books';
@import 'component-recipes';
@import 'component-faqs';
@import 'component-form';


.hero {
	padding: 0 1.6rem !important;
	margin: 0 auto;
	display: flex;
	width: 100%;
	max-width: 140rem;

	&--flip {

		.content {
			order: 2;
		}

	}

	.image, .content {
		width: 50%;
	}

	.image {
		background: transparent url(#) no-repeat center center; // /assets/hero-jane-01.jpg
		background-size: cover;
		min-height: 40rem;
	}

	.content {
		display: flex;
		padding: 3.2rem;
		background: white;


		&__wrapper {
			margin: auto;
			width: 100%;
			// max-width: 60rem;
		}

	}

	.button {
		min-width: 15rem;
	}

}

.logos {
	display: flex;
	justify-content: center;
}

.logo__img {
	width: 20rem;
	height: 10rem;
	background:transparent url(#) no-repeat center center;
	background-size: 80% auto;
	opacity: 0.5;
}

.promo {
	display: flex;
	align-items: stretch;
	background: #f7f7f7;
	overflow: hidden;

	&__image {
		width: 100%;
		max-width: calc(50% + 3.2rem);
		padding: 3.2rem;
		background: white;

		img {
			width: 100%;
			display: block;
		}

	}

	&__content {
		flex: 1 1 auto;
		padding: 3.2rem;
		padding-left: 0;
		background: white;
		position: relative;

		p {
			max-width: 70rem;
		}

		h2 a {
			opacity: 1;
			transition: opacity 0.3s;
			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__cta {

		.button {
			margin-right: 1.6rem;
		}

	}

	&__tag {
		background: $active;
		color: white;
		display: inline-block;
		width: 15rem;
		transform: rotate(45deg);
		position: absolute;
		top: 2.5rem;
		right: -3.5rem;
		text-align: center;
	}

	&__subtitle {
		@extend %small-caps;
		color: #999;
	}

	&--flip {

		.promo__image {
			order: 2;
		}

		.promo__content {
			padding-right: 0;
			padding-left: 3.2rem;
		}

	}

	&--book {
		display: block;

		.promo__image {
			max-width: 100%;
			padding-bottom: 0;
			position: relative;
			z-index: 5;
		}

		.promo__content {
			padding-left: 3.2rem;
		}

		.promo__cta {

			.button {
				margin-bottom: 0.8rem;
			}

		}

		@media only screen and (min-width:660px) {
			display: flex;

			.promo__content {
				padding-left: 0;
			}

			.promo__image {
				max-width: 45rem;
				padding-bottom: 3.2rem;
			}
		}

	}

}

#recipes-exlusive, #recipes-featured {

	.promo {
		display: block;

		&__image {
			max-width: 100%;
			padding-bottom: 0;
		}

		&__content {
			padding: 3.2rem;
		}

		@media only screen and (min-width:750px) {
			display: flex;

			&__image {
				padding-bottom: 3.2rem;
				max-width: calc(50% + 3.2rem);
			}

			&__content {
				padding-left: 0;
			}

			&--flip {

				.promo__content {
					padding-left: 3.2rem;
					padding-right: 3.2rem;
				}

			}

		}
	}


}

#about-hero, #recipes-hero {

	&.hero {
		flex-wrap: wrap;
		width: 100%;

		.image, .content {
			width: 100%;
		}

		.image {
			padding-top: 65%;
			order: 1;
			min-height: auto;
		}

		.content {
			order: 2;
		}

		.cta {

			.button {
				margin-bottom: 0.8rem;
			}

		}

		@media only screen and (min-width:1000px) {
			display: flex;

			.image, .content {
				width: 50%;
			}

			.content {
				order: 1;
			}

			.image {
				padding-top: 0;
				order: 2;
			}
		}
	}

}

#about-publications {
	text-align: center;
}

.logo {
	display: inline-block;
	margin: 0 0.8rem;
}

#about-instagram-gallery {
	text-align: center;
	padding-top: 0;
	padding-bottom: 0;
}

.instagram-placeholder {
	width: 100%;
	text-align: center;
	padding: 24px 0;

	svg {
		width:64px;
		height:64px;

		path {
			fill: silver;
		}
	}

	p {
		margin-top: 0;
	}
}

.instagram-gallery {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: #f7f7f7;
	padding: 2.4rem;
	margin-top: 1.2rem;
	margin-bottom: 4.8rem;

	&__item {
		display: block;
		height: auto;
		padding: 0.8rem;
		width: 50%;

		&:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
			display: none;
		}

		@media only screen and (min-width:500px) {
			width: 33.3333%;
			&:nth-child(5), &:nth-child(6) {
				display: inline-block;
			}
		}

		@media only screen and (min-width:740px) {
			width: 25%;
			&:nth-child(7), &:nth-child(8) {
				display: inline-block;
			}
		}

		@media only screen and (min-width:1000px) {
			width: 20% !important;
			&:nth-child(9), &:nth-child(10) {
				display: inline-block;
			}
		}

		a {
			display: block;
			background: transparent url(#) no-repeat center center;
			background-size: cover;
			opacity: 1;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.8;
			}
		}

		img {
			display: block;
			width: 100%;
		}
	}

}

#recipes-all, #categories-all {
	padding-bottom: 4.8rem;
}

#global-contact-cta {
	border-top: 1px solid darken($background-color, 10%);
	padding: 4.8rem 0 6.4rem 0;
}

.global-contact-cta {
	display: flex;
	flex-wrap: wrap;

	&__promo {
		width: 33.333333%;
		position: relative;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-top: 4.8rem;
		padding-bottom: 3.9rem;

		&:first-of-type {
			padding-top: 0;
		}

		@media only screen and (min-width:660px) {
			padding-top: 0;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			width: 50%;

			&:nth-child(2) {
				padding-right: 0;
			}

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				padding-top: 4.8rem;
			}

		}

		@media only screen and (min-width:1000px) {
			width: 33.333333% !important;
			&:first-of-type {
				padding-left: 0;
			}
			&:last-of-type {
				padding-top: 0 !important;
				padding-right: 0;
				padding-left: 1.6rem !important;
			}
		}



	}

	.cta {
		position: absolute;
		bottom: 0;
		// left: 1.6rem;

		.button {
			margin-left: 0;
			margin-right: 1.6rem;
		}
	}

}

.crumb {
	position: absolute;
	top: -2.4rem;
	left: 2rem;
	@extend %small-caps;

	svg {
		display: inline-block;
		vertical-align: -0.75rem;
		margin-left: -0.4rem;

		path {
			fill: $active;
		}
	}

	+ h1 {
		padding-bottom: calc(4.2rem - 0.8rem);
	}
}

#books, #recipe-page {
	position: relative;

	h1 {
		padding-bottom: 4rem;
	}

}

.pad-box {
	background: white;
	padding: 3.2rem;

	> p {
		margin-top: 0;
	}
}

.categories {
	display: flex;
	flex-wrap: wrap;
}

.category {

	&__item {
		position: relative;
		margin: 0.8rem;
		opacity: 1;
		transition: opacity 0.3s;
		width: 100%;

		@media only screen and (min-width:500px) {
			width: calc(50% - 0.8rem); // (0.8 * 2) / 2 :)

			&:nth-child(2n+1) {
				margin-left: 0;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}

		}


		@media only screen and (min-width:770px) {
			width: calc(33.3333% - 1.0666rem); // (0.8 * 4) / 4 :)

			&:nth-child(2n+1) {
				margin-left: 0.8rem;
			}
			&:nth-child(2n) {
				margin-right: 0.8rem;
			}

			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}


		@media only screen and (min-width:1000px) {
			width: calc(25% - 1.2rem) !important; // (0.8 * 6) / 4 :)

			&:nth-child(3n+1) {
				margin-left: 0.8rem !important;
			}
			&:nth-child(3n) {
				margin-right: 0.8rem !important;
			}

			&:nth-child(4n+1) {
				margin-left: 0 !important;
			}
			&:nth-child(4n) {
				margin-right: 0 !important;
			}
		}

		&:hover {
			opacity: 0.8;
		}

	}

	&__image {
		width: 100%;
		display: block;
	}
	&__title {
		position: absolute;
		bottom: 0.8rem;
		left: 0.8rem;
		right: 0.8rem;
		padding: 1.6rem;
		background: rgba(255,255,255,0.9);
		@extend %small-caps;
	}

}

#contact-faqs {
	padding-top: 2.4rem;
}
