.book-review {

	&__quote {

		text-align: left;
		padding: 2.4rem 4.8rem;
		margin: 4.8rem auto;
		font-size: 3.2rem;
		max-width: 80rem;
		position: relative;
		border-left: 5px solid $active;

		p:first-of-type {
			margin-top: 0;
		}

	}

	&__citation {
		display: block;
		text-align: right;
		font-style: normal;
		@extend %small-caps;
		font-size: 1.7rem;
	}

}
