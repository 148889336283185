#contact-form {
	padding-bottom: 6.4rem;
}

.contact-form {

	@media only screen and (min-width:870px) {
		display: flex;

		&__intro {
			width: 50rem;
			padding-right: 4.8rem;
		}

		&__fields {
			flex: 1 1 auto;
		}
	}


}

.form {
	max-width: 80rem;

	label, input, textarea {
		display: block;
	}

	label {
		margin-bottom: 1.6rem;
	}

	input, textarea {
		border: 1px solid darken($background-color, 10%);
		padding: 0.8rem;
		width: 100%;
		transition: border, 0.3s;

		&:focus {
			border-color: $active;
		}
	}

	&__row {

		@media only screen and (min-width:560px) {
			display: flex;
			justify-content: space-between;

			label {
				width: 48%;
			}
		}
	}

}
