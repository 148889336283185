.global-footer {
	background: transparent;
	@extend %small-caps;
	font-size: 1.1rem;
	color: darken($background-color, 30%);
	padding: 3.2rem 0;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-top: 1px solid darken($background-color, 10%);

	a {
		color: $active;
	}
}
