.recipes {
	display: flex;
	flex-wrap: wrap;
}

.recipe {
	display: block;
	color: $base-text-color;
	position: relative;
	margin: 0.8rem;
	background: white;
	opacity: 1;
	transition: opacity 0.3s;

	width: 100%;

	@media only screen and (min-width:500px) {
		width: calc(50% - 0.8rem); // (0.8 * 2) / 2 :)

		&:nth-child(2n+1) {
			margin-left: 0;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}
	}


	@media only screen and (min-width:750px) {
		width: calc(33.3333% - 1.06666rem); // (0.8 * 4) / 3 :)

		&:nth-child(2n+1) {
			margin-left: 0.8rem;
		}

		&:nth-child(2n) {
			margin-right: 0.8rem;
		}

		&:nth-child(3n+1) {
			margin-left: 0;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}

	}

	&:hover {
		opacity: 0.8;
	}

	&__image {
		display: block;
		width: 100%;
	}

	&__title {
		display: block;
		color: $active;
		padding: 1.6rem;
		padding-bottom: 0;
	}

	&__publication {
		display: block;
		@extend %small-caps;
		opacity: 0.6;
		padding: 0.4rem 1.6rem 1.6rem 1.6rem;
	}

	&__credit {
		@extend %small-caps;
		font-size: 0.9rem;
		opacity: 0.4;
		position: absolute;
		right: 1.6rem;
		bottom: 0.4rem;

		span {
			color: black;
		}
	}

	&__description {
		display: none;
	}

	&__tags {
		margin-top: 0.8rem;
		padding-top: 0.8rem;
		display: block;
		border-top: 1px solid #ccc;
	}

	&__tag {
		display: block;
		font-size: 1rem;
		text-align: right;
	}

	&.featured {
		display: flex;
		width: 100%;

		.recipe__image {
			width: 50%;
			height: 50%;
			padding-right: 0.8rem;
		}

		.recipe__body {
			width: 50%;
			padding-left: 0.8rem;
		}

		.recipe__description {
			display: block;
		}

	}

	&.new {
		position: relative;

		&:before {
			content:'NEW';
			position: absolute;
			top: 0.5%;
			left: 0.5%;
			padding: 0.8rem 1.6rem;
			background: $background-color;
		}

	}

}
