body {
	padding-top: 14.4rem;

	@media only screen and (min-width:650px) {
		padding-top: 0;

		&.scroll-nav {
			padding-top: 20.9rem;

			.global-header {
				position: fixed;
				left: 0;
				z-index: 10;
				transition: all 0.5s;

				h1 {
					a {
						height: 8rem;
						width: 25rem;
					}
				}

				&.on {
					top: 0;
					box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
				}
				&.off {
					top: -20.9rem;
				}
			}
		}

	}

}

.global-header {
	background: white;
	padding: 1.6rem;
	width: 100%;

	position: fixed;
	top:0;

	z-index: 10;
	box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);

	@media only screen and (min-width:650px) {
		position: static;
		top: auto;
		box-shadow: none;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		max-width: $wrapper-width;
		margin: 0 auto;
	}

	h1 {
		padding: 0;
		text-indent: -9999em;
		overflow: hidden;

		a {
			display: block;
			background:transparent url() no-repeat center center;
			background-size: contain;
			height: 8rem;
			width: 25rem;

			@media only screen and (min-width:650px) {
				height: 14rem;
				width: 37.5rem;
			}
		}

	}

}

.global-nav {
	display: flex;
	justify-content: center;
	font-family: $heading-font;
	align-items: center;

	&__item {
		@extend %small-caps;
		color: $active;
		font-size: 1.1rem;
		margin-left: 1.6rem;

		@media only screen and (min-width:650px) {
			font-size: 1.4rem;
			margin-left: 2.4rem;
		}

		&:first-of-type {
			margin-left: 0;
		}

		&.active {
			opacity: 0.5;
		}

	}

	&__link {
		color: $active !important;
		padding: 0.8rem;
		opacity: 1;
		transition: opacity 0.3s;

		.personal-touch {
			display: none;
			@media only screen and (min-width:460px) {
				display: inline;
			}
		}

		&:hover {
			opacity: 0.8;
		}
	}

}
